import React, {useState, useEffect} from 'react';
import NavItem from "./NavItem.js";
import {AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineArrowUp} from "react-icons/ai";

import './Profile.css';
import Translation from './texts.json';

function Profile(props) {
	const [content,setContent]=useState({});
	const elements=[
		["// ΠΟΙΟΙ ΕΙΜΑΣΤΕ //"],
		["./assets/dnCard.png","Το 2022 ξεκινήσαμε μια προσπάθεια να φτιάξουμε την δικιά μας επιχείρηση. Το ΔΝ Development Studio έχει ως σκοπό να βοηθήσει τοπικές επιχειρήσεις να αναδειχθούν. Σεβόμαστε και θέλουμε να προωθήσουμε την Ελλάδα και τις δυνατότητες της. Γι αυτό αποφασίσαμε, ακόμα κι αν πια η έδρα μας βρίσκεται στην Νέα Υόρκη, να προσφέρουμε την υπηρεσία μας στην Ελλάδα, ακόμη μια φορά."],
		["./assets/missionCard.png","Γιατί στην Ελλάδα;\nΤο προσωπικό μας αποτελείται κυρίως από Έλληνες. Ξέροντας πως η Ελλάδα μας δοκιμάστηκε και δοκιμάζεται οικονομικά, αποφασίσαμε να προσφέρουμε την υπηρεσία μας, με σκοπό να βοηθήσουμε τον εκσυγχρονισμό της. Έτσι, προχωρούμε στην παροχή της υπηρεσίας μας σε ασύγκριτα χαμηλότερη τιμή από αυτήν που προσφέρουμε σε άλλες χώρες."],
		["./assets/njit.png","Ο CEO μας είναι απόφοιτος του New Jersey Institute of Technology (Πολυτεχνείο το οποίο εδρεύει στην Νέα Υερσέη των ΗΠΑ). Ολοκλήρωσε τις σπουδές του το 2021. Έχει εμβαθύνει στο αντικείμενο της διαχείρισης και ανάπτυξης διαδικτυακών μηχανισμών, όπως ιστοσελίδες. Σε περίπτωση που θέλετε να μάθετε περισσότερα για το NJIT, σας παραθέτουμε τον διαδικτυακό σύνδεσμο."],
		["// ΤΙ ΣΑΣ ΠΡΟΣΦΕΡΟΥΜΕ //"],
		["./assets/adds.png","ΔΙΑΦΗΜΙΣΗ","Γιατί να επενδύσετε στη διαφήμιση;"],
		["./assets/cart.png","ΔΙΑΔΙΚΤΥΑΚΗ ΑΓΟΡΑ","Πως κάνετε πωλήσεις εξ αποστάσεως;"],
		["./assets/calendar.png","ΑΜΕΣΗ ΨΗΦΙΑΚΗ ΕΠΙΚΟΙΝΩΝΙΑ","Πως βοηθά την επιχείρηση σας;"],
		["// ΤΕΧΝΟΛΟΓΙΕΣ //"],
		["./assets/graphics.png","Χρησιμοποιούμαι τα πιο σύγχρονα μέσα και προγράμματα για να φτιάξουμε εικονίδια, σήματα και εικόνες για εσάς. Αυτό γίνεται με την βοήθεια γραφίδων και πινάκων σχεδίασης που χρησιμοποιεί ένας γραφίστας. Κάθε ψηφιακή εικόνα που μπαίνει στον ιστότοπο σας έχει ελεγχθεί ή σχεδιαστεί από τον γραφίστα της ομάδας μας, ώστε να έχετε το καλύτερο δυνατόν προϊόν."],
		["./assets/databases.png","Για την καταχώριση χρηστών, δημιουργία ραντεβού και αγορές, χρησιμοποιούμε βάσεις δεδομένων. Οι βάσεις δεδομένων αυτές φιλοξενούνται σε δικούς μας σέρβερς τους οποίους συντηρούμε εμείς. Αυτό είναι μια πρακτική που δεν ακολουθούν πολλοί, αλλά εμείς για να βοηθήσουμε τους πελάτες μας το προτιμούμε από το να τους επιβαρύνουμε με το κόστος του να φτιάξουν το δικό τους σέρβερ."],
		["./assets/programmingSkills.png",""],
	];

	const [scroll, setScroll] = useState(1);
	var scroller=1;
	let currentSet="set1";

	const MoveToNextSet = (direction) =>{
		
		if(direction=="left"){
			setScroll(scroll-1)
			currentSet="set".concat(scroll);
		}
		else if(direction=="right"){
			setScroll(scroll+1)
			currentSet="set".concat(scroll);
		}
		else{
			console.log("WTF?");
		}
		//document.getElementById(currentSet).scrollIntoView();
	};
	useEffect(()=>{
		if(window.screen.width>=window.screen.height){
			document.getElementById("set".concat(scroll)).scrollIntoView();
		}
		if(props.language=="English"){
			setContent(Translation.english);
		}
		else if(props.language=="Ελληνικά"){
			setContent(Translation.greek);
		}
	});

	return (
		<div id="aboutme" className="generalContainer">
			<div id="container" >
				{ scroll > 1 && <button className="leftArrow"  onClick={() => {MoveToNextSet("left")}}><AiOutlineDoubleLeft /></button> }
				<div id="set1" className="profileContainer">
					<div className="profileTitle">// {content.aboutUsTitle1} //</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[1][0]} />
						</div>
						<div className="profileItemBottom">
							{content.aboutUs1}
						</div>
					</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[2][0]} />
						</div>
						<div className="profileItemBottom">
							{content.aboutUs2}
						</div>
					</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[3][0]} />
						</div>
						<div className="profileItemBottom">
							{content.aboutUs3}
							<a href="https://www.njit.edu/" target="_blank">https://www.njit.edu/</a>
						</div>
					</div>
				</div>
				<div id="set2" className="profileContainer">
					<div className="profileTitle">// {content.aboutUsTitle2} //</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[5][0]} />
						</div>
						<div className="profileItemBottomSet2">
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs4Title}</h5>
							<p style={{color : "#438a9c"}}>{content.aboutUs4SubTitle}</p>
							<p style={{color : "#7ac4a5"}}>{content.aboutUs4}</p>
						</div>
					</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[6][0]} />
						</div>
						<div className="profileItemBottomSet2">
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs5Title}</h5>
							<p style={{color : "#438a9c"}}>{content.aboutUs5SubTitle}</p>
							<br/>
							<p style={{color : "#7ac4a5"}}>{content.aboutUs5}</p>
						</div>
					</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[7][0]} />
						</div>
						<div className="profileItemBottomSet2">
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs6Title}</h5>
							<p style={{color : "#438a9c"}}>{content.aboutUs6SubTitle}</p>
							<p style={{color : "#87a8b0"}}>{elements[7][3]}</p>
							<p style={{color : "#7ac4a5"}}>{content.aboutUs6}</p>
						</div>
					</div>
				</div>
				<div id="set3" className="profileContainer">
					<div className="profileTitle">// {content.aboutUsTitle3} //</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[9][0]} />
						</div>
						<div className="profileItemBottom">
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs7Title}</h5>
							<p>{content.aboutUs7}</p>
						</div>
					</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[10][0]} />
						</div>
						<div className="profileItemBottom">
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs8Title}</h5>
							<p>{content.aboutUs8}</p>
						</div>
					</div>
					<div className="profileItem">
						<div className="profileItemTop">
							<img src={elements[11][0]} />
						</div>
						<div className="profileItemBottom">
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs9Title1}</h5>
							<p style={{color : "#7ac4a5"}}>HTML<br/>Javascript & Typescript<br/>PHP<br/>SQL<br/>Python</p>
							<h5 style={{color : "#60a5b5", marginTop :"2vh"}}>{content.aboutUs9Title2}</h5>
							<p style={{color : "#7ac4a5"}}>Angular<br/>React<br/>Django</p>
							<br/>
						</div>
					</div>
				</div>
			</div>
			{ scroll < 3 && <button className="rightArrow"  onClick={() => {MoveToNextSet("right")}}><AiOutlineDoubleRight /></button> }
		</div>

	);
}

export default Profile;