import React, {useState} from 'react';
import { BiHome , BiBook ,BiBriefcase, BiCube, BiMoviePlay, BiCloud, BiPaint, BiMenu, BiEnvelope, BiShoppingBag} from "react-icons/bi";
import { FaYoutube , FaGithub } from "react-icons/fa";
import { AiOutlineInfo } from "react-icons/ai";

import './App.css';



function NavItem(props){
	if(props.icon == "BiHome"){
		return (
			<BiHome className={props.class}/>
		);
	}
	else if(props.icon == "AiOutlineInfo"){
		return (
			<AiOutlineInfo  className={props.class}/>
		);
	}
	else if(props.icon == "BiCube"){
		return (
			<BiCube  className={props.class}/>
		);
	}
	else if(props.icon == "BiShoppingBag"){
		return (
			<BiShoppingBag  className={props.class}/>
		);
	}
	else if(props.icon == "BiMoviePlay"){
		return (
			<BiMoviePlay  className={props.class}/>
		);
	}
	else if(props.icon == "BiCloud"){
		return (
			<BiCloud  className={props.class}/>
		);
	}
	else if(props.icon == "BiPaint"){
		return (
			<BiPaint  className={props.class}/>
		);
	}
	else if(props.icon == "BiMenu"){
		return (
			<BiMenu  className={props.class}/>
		);
	}
	else if(props.icon == "BiEnvelope"){
		return (
			<BiEnvelope  className={props.class}/>
		);
	}
}

export default NavItem;