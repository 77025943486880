import React, {useState, useEffect} from "react"
import './Promo.css';

import {Blurhash} from 'react-blurhash'

import {AiOutlineArrowUp,AiOutlineSync} from "react-icons/ai";

function Promo(props) {
	const date = new Date();
	const months =['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const [visible, setVisible]=useState("flex");
	const styling={
		display:visible
	};
	const stylingPicture="./assets/promo/promo"+months[date.getMonth()+1]+(date.getFullYear()).toString().substring(2)+".png";
	const[imageLoaded,setImageLoaded]=useState(false);
	useEffect(()=>{
		const img = new Image()
		img.onload = () => {
			setImageLoaded(true)
		}
		img.src =stylingPicture
	},[stylingPicture])
	
	return (
		<div id="promo" className="promoContainer" style={styling}>

			<div style={{display: imageLoaded ? 'none' : 'block'}}>
				<div className="promoBody" style={{display:"flex", flexDirection:"column",justifyContent:"space-around",textAlign:"center"}}>
					<p><AiOutlineSync  className="spinObject"/></p>
				</div>
			</div>
			<div style={{display: !imageLoaded ? 'none' : 'block'}}>
				<div style={{display:"flex", flexDirection:"column",justifyContent:"space-around",textAlign:"center"}}>
					<img loading="lazy" className="promoBody" src={stylingPicture}/>
				</div>
			</div>
		</div>
	)
}
export default Promo;