import React, {useEffect, useState} from 'react';
import NavItem from "./NavItem.js";
import axios from 'axios';
import {AiOutlineSync} from "react-icons/ai";

import './Service.css';
import Translation from './texts.json';

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
function Service(props) {
	const [cs,setCs]=useState("");
	const [gotNewSecret,setGotNewSecret]=useState(false);
	const getSecret= async (amount,code,currency) => {
		await axios.post("https://dndevelopmentstudio.com:1234/stripe/paymentSecret1993", {
			amount: amount,
			code: code,
			currency: currency
		}).then(function (res) {
			//setGotSecret(false);
			//return response.body.cs;
			//setCs(res.body.cs);
			setCs(res.data.cs);
			setGotNewSecret(true);
		})
	}

	const [content,setContent]=useState(Translation.greek);
	const [leftCard,setLeftCard]=useState([
			["Είστε Σημαντικοί για Εμάς","Το project σας έχει σημασία για εμάς. Το πιο σημαντικό για εμάς, είναι η δουλειά μας, και η φήμη μας που καθορίζεται από το προϊόν που παράγουμε. Είμαστε περήφανοι για αυτό που κάνουμε.\n\nΠροσπαθούμε να βοηθήσουμε επιχειρήσεις, ιδιαίτερα τις μικρές, να αναδειχθούν και να αναπτυχθούν. Αυτός είναι ο λόγος για τον οποίο θέλουμε να παράγουμε την καλύτερη ποιότητα και να διατηρήσουμε την ελάχιστη τιμή για την μικρή επιχείρησή όσο το δυνατόν χαμηλότερη. Ο ιδρυτής μας προέρχεται από μια οικογένεια που είχε μια μικρή επιχείρηση και καταλαβαίνει, περισσότερο από τους περισσότερους, πόσο σημαντικό είναι να έχεις έναν ιστότοπο που να δείχνει τι προσφέρεις στο κοινό."],
			["Δικαιώματα Κώδικα","Αφού λάβουμε την τελική πληρωμή, θα είστε ιδιοκτήτης του ιστότοπου και είστε ελεύθεροι να κάνετε ό,τι θέλετε με αυτόν. Εάν επιλέξετε να αφήσετε κάποιον να το αλλάξει με οποιονδήποτε τρόπο, δεν θα φέρουμε καμία ευθύνη και το πακέτο συντήρησης θα ακυρωθεί χωρίς επιστροφή χρημάτων. Κρατάμε αρχείο της δουλειάς μας. <br/><br/>Μπορεί στο παραπάνω σημείο να αποκτήσατε την κυριότητα του κώδικα, αλλά διατηρούμε επίσης την κυριότητα του κώδικα μας. Αυτό σημαίνει ότι μπορούμε να ανακυκλώσουμε κομμάτια του κώδικα μας σε άλλα project μας."],
			["Τιμές & Διαπραγματεύσεις","Μπορεί να διαπραγματευτούμε κάποιες τιμές, αλλά υπάρχουν κάποιες γραμμές που δεν μπορούμε να περάσουμε. Λάβετε υπόψη ότι η τιμολόγηση έχει διαμορφωθεί με συγκεκριμένα κριτήρια με βάση τον χρόνο και την προσπάθεια που χρειάζονται ορισμένα κομμάτια. Η ελάχιστη τιμή είναι η ελάχιστη τιμή. Δεν κάνουμε ποιοτικές περικοπές, και ως εκ τούτου το ελάχιστο μας ορίζεται εκεί που έχει οριστεί. Είμαστε πρόθυμοι να ακούσουμε τις ανάγκες και την προσέγγισή σας και θα διατηρήσουμε τις τιμές όσο το δυνατόν πιο κοντά στο ελάχιστο. Ως εκ τούτου, θα συζητήσουμε μαζί σας όσο χρειάζεται για να συμφωνήσουμε την τιμή."],
			["Πληρωμές και Επιστροφές","Θα υπάρξει προκαταβολή, η οποία είναι το μισό του ποσού που θα συμφωνήσουμε ότι θα σας κοστίσει ο ιστότοπος. Αυτό το ποσό δεν επιστρέφεται. Επομένως, εάν έχετε αλλάξει γνώμη, δεν θα μπορέσουμε να το επιστρέψουμε. \n\nΑφού σας παρουσιάσουμε το τελικό προϊόν και συμφωνήσετε ότι είναι καλοφτιαγμένο (πριν από τη φάση ανάρτησης, που είναι το τελευταίο στάδιο), θα σας ζητήσουμε το υπόλοιπο ποσό. Μόνο μετά την παραλαβή του, θα προχωρήσουμε στην ανάρτηση του ιστότοπού σας και θα σας παραδώσουμε τον κώδικα και όλα τα σχετικά με αυτόν."],
			["Κανόνες Συντήρησης","Το μηνιαίο πρόγραμμα συντήρησης καλύπτει δώδεκα (12) γύρους σέρβις, έναν (1) κάθε έναν (1) μήνα περίπου. Αυτοί οι γύροι υπηρεσιών περιλαμβάνουν ενημερώσεις για το περιεχόμενο (όπως η προσθήκη εικόνων ή προϊόντων που εμφανίζονται) και διορθώσεις σε στοιχεία που δεν συμπεριφέρονται σωστά. Η υπηρεσία αφορά μόνο τα υπάρχοντα κομμάτια. Έτσι, η προσθήκη νέων λειτουργιών δεν περιλαμβάνεται σε αυτό το πρόγραμμα. \nΑν οποιοσδήποτε άλλος εκτός από εμάς αλλάξει τον κώδικα θα έχει ως αποτέλεσμα την ακύρωση του μηνιαίου προγράμματος και δεν θα σας δοθεί επιστροφή χρημάτων. Εάν ο στόχος σας είναι να προσθέσετε επιπρόσθετες λειτουργίες (που δεν υπάρχουν ήδη), δεν καλύπτονται από αυτό το πακέτο."]
		]);
	const [rightCard,setRightCard]= useState([
			["Βασικό Πακέτο","Ένας ιστότοπος μικρής επιχείρησης απαιτεί ένα ελάχιστο επίπεδο πολυπλοκότητας. Δεν μπορεί να έχει μηχανισμό σύνδεσης ή εγγραφής, ούτε να έχει σύνδεση με βάση δεδομένων (όπως απαιτείται για παραγγελία προϊόντων). Επίσης, δεν μπορείτε να λαμβάνετε ειδοποιήσεις μέσω email από αυτόν τον ιστότοπο. Όλα αυτά απαιτούν άλλο κόστος. Λιγότερη πολυπλοκότητα σημαίνει ότι έχετε φθηνότερη τιμή. Σας υπόσχομαι πως θα πάρετε περισσότερο από αυτό που πληρώσατε.","850€"],
			["Μηνιαία Συντήρηση"," Είναι προαιρετικό. Τι περιλαμβάνει; Περιλαμβάνει δύο (2) μεγάλους γύρους σέρβις συντήρησης, έναν (1) περίπου κάθε έξι (6) μήνες. Αυτοί οι γύροι συντήρησης περιλαμβάνουν αλλαγές στο περιεχόμενο ή διορθώσεις στα υπάρχοντα στοιχεία. Εάν θέλετε να προσθέσετε λειτουργίες που δεν υπάρχουν ήδη, ή απαιτούν περισσότερους γύρους συντήρησης, θα πρέπει να διαπραγματευτείτε μια τιμή. Εάν ο ιστότοπος έχει τροποποιηθεί από οποιονδήποτε άλλο εκτός από εμένα, αυτή η επιλογή δεν είναι πλέον διαθέσιμη.","100€ ανά εξάμηνο"],
			["Χρόνος Ανάπτυξης Ιστοσελίδας","Ο χρόνος που υπολογίζουμε ότι θα χρειαστεί για μια τυπική ιστοσελίδα είναι τέσσερις (4) μήνες. Ωστόσο, μπορεί να χρειαστεί να είναι έτοιμο σε συντομότερο χρονικό διάστημα. Μπορούμε να συζητήσουμε την συντόμευσης της διαδικασίας. Εάν συμφωνήσουμε ότι μπορεί να γίνει σε μικρότερο χρονικό διάστημα, θα πρέπει να πληρώσετε 250€ για κάθε μήνα λιγότερο από τους τέσσερις (4). Υπάρχει πιθανότητα κάποια πρότζεκτ να είναι έτοιμα πολύ νωρίτερα από τέσσερις (4) μήνες. Θα πληρώσετε επιπλέον μόνο εάν έχετε ζητήσει, εκ των προτέρων, να συντομεύσετε την αρχική περίοδο. Συνήθως κάνουμε λιγότερο από τέσσερις (4) μήνες."," λιγότερο από 250€ μηνιαίως"],
			["Ανάρτηση Σελίδας στο Internet","Αυτό θα καθοριστεί από το κόστος απόκτησης ενός domain για τον ιστότοπό σας και από τα έξοδα ανάρτησης της σελίδας του. Η τιμή θα καλύψει την ανάρτηση για τον πρώτο χρόνο. Στη συνέχεια το κόστος εγκατάστασης θα γίνεται με δική σας ευθύνη. Θα σας εξηγήσω προσωπικά πώς μπορείτε να πληρώνεται μόνοι σας τα έξοδα αυτά που επαναλαμβάνονται ετησίως. Θα σας δείξω την τιμή τόσο του domain όσο και των εξόδων ανάρτησης της σελίδας και θα μάθουμε μαζί τις λεπτομέρειες. Θα προχωρήσω στην ανάπτυξη του ιστότοπού σας μόνο αφού έχουμε συμφωνήσει σχετικά με τους όρους και τις λεπτομέρειες της ανάπτυξης.","Λιγότερο από 50€ (άπαξ)"],
			["Επιπρόσθετες Λειτουργίες","Εάν χρειάζεστε κάτι περισσότερο από τα βασικά, όπως μια σύνδεση βάσης δεδομένων ή άλλη πολύπλοκη λειτουργικότητα, μπορούμε να βρούμε μια τιμή. Όσο υψηλότερο είναι το επίπεδο πολυπλοκότητας, τόσο περισσότερη δουλειά απαιτείται. επομένως, θα είναι πιο ακριβό. <br/> Θέλετε να προσθέσετε περιεχόμενο μόνοι σας; Θέλετε να κρατάτε αρχεία και πολλά άλλα χωρίς να χρειάζεστε συντήρηση και να παρακολουθείτε το κόστος εγκατάστασης; \n\n Μπορούμε να φτιάξουμε για εσάς ένα μηχάνημα server και να παρέχουμε τον προγραμματισμό του.","Διαπραγματεύσιμη"]
		]);

	const [currentLang,setCurrentLang]=useState("Ελληνικά");
	useEffect(()=>{
		if((props.language=="English") && (currentLang!="English")){
			setContent(Translation.english);
			setCurrentLang("English");
			setLeftCard(
				[
					["You are Important to Us","Your project is important to us. The most important thing for us is our work, and our reputation is determined by the product of our work. We are proud of what we do. \n\nWe try to help businesses, especially small ones, to stand out and grow. This is why we want to produce the best quality and keep our small business minimum price as low as possible. Our founder comes from a small business family and understands, more than most, how important it is to have a website that shows what you offer to the public."],
					["Code Ownership","After we receive the final payment, you will own the site and are free to do whatever you want with it. If you do let someone change in any way we will stop being responsible for the webpage and the maintenance package will be canceled and void, without refund. We keep a record of our work. \n\nYou may have gained, at that point, ownership of the code, but we also retain ownership of our code. This means that we can recycle pieces of our code in our other projects."],
					["Prices & Negotiations","We may negotiate some prices, but there are some lines we cannot cross. Please note that pricing is set with specific criteria based on time, which some projects require. The minimum value is the minimum value. We do not make quality cuts, and therefore our minimum is set where it is set. We are willing to listen to your requirements and approach and keep the prices as close to the minimum as possible. Therefore, we will discuss with you as necessary to agree the price."],
					["Payments and Returns","There will be a down payment, which is half of what we agree the site will cost. This amount is non-refundable. Therefore, if you have a change of heart, we will not be able to return this payment. \n\nAfter we show you the finished product and you agree that it is well made (before the uploading to the server phase, which is the last stage), we will ask for the remaining amount. Only after receiving it, we will proceed with the posting of your website and deliver to you the code and everything related to it."],
					["Rules of Maintenance","The monthly maintenance schedule covers twelve (12) service rounds, one (1) approximately every one (1) month. These service rounds include updates to content (such as adding images or products used) and fixes to misbehaving items. The service only applies to existing tracks. So adding new features is not included in this plan. \nIf anyone other than us has changes the code would result in the cancellation of the monthly plan and you will not be given a refund. If your goal is to add additional features (that don't already exist), they are not covered by this package."]
				]
			);
			setRightCard(
				[
					["Basic Package","A small business website requires a minimum level of complexity. It cannot have a login or registration mechanism, nor can it have a database connection (as it does for ordering products). Also, you cannot receive email notifications from this site. All these require time to be deloped. Less complexity means you get a cheaper price. \n\nI promise you will get more than what you paid for.","1100$"],
					["Monthly Maintenance"," It is optional. What does it include? Includes two (2) major maintenance service rounds, one (1) approximately every six (6) months. These rounds of maintenance include changes to content or fixes to existing items. If you want to add features that don't already exist, or require more maintenance rounds, you'll need to negotiate a price. If any site has been modified by anyone other than me, this option is no longer available.","200$ semiannually"],
					["Website Development Time","The time we estimate it will take for a typical website is four (4) months. However, you may need it to be ready in a shorter time. We can discuss shortening the process. If we agree that it can be done in a shorter period of time, you will have to pay $500 for each month less than four (4). There is a possibility that some projects will be ready much earlier than four (4) months. You will only pay extra if you have requested, in advance, to shorten the initial period. We usually do less than four (4) months."," Less than 350$ monthly"],
					["Uploading a Page","This will be determined by the cost of acquiring a domain for your site and the cost of posting the page. The price will cover the cost for the first year. After that, the installments will be your responsibility. We will personally explain to you how you can pay these yearly recurring expenses yourself. I will show you the price of both the domain and the costs of hosting the page and we will find out the details together. I will proceed with the development of your website only after we agree on the terms and details of the development.","Less than 50$ (once)"],
					["Additional Functions","If you need more than the basics, like a database connection or other complex functionalities, we can find a price. The higher the level of complexity, the more work is required. Therefore, it will be more expensive. \n Do you want to add content yourself? Do you want to keep records and more without needing maintenance and keeping track of setup costs? \n\n We can build a server machine for you and provide its programming.","Negotiable"]
				]
			);
		}
		else if((props.language=="Ελληνικά") && (currentLang!="Ελληνικά")){
			setContent(Translation.greek);
			setCurrentLang("Ελληνικά")
			setLeftCard(
				[
					["Είστε Σημαντικοί για Εμάς","Το project σας έχει σημασία για εμάς. Το πιο σημαντικό για εμάς, είναι η δουλειά μας, και η φήμη μας που καθορίζεται από το προϊόν που παράγουμε. Είμαστε περήφανοι για αυτό που κάνουμε.\n\nΠροσπαθούμε να βοηθήσουμε επιχειρήσεις, ιδιαίτερα τις μικρές, να αναδειχθούν και να αναπτυχθούν. Αυτός είναι ο λόγος για τον οποίο θέλουμε να παράγουμε την καλύτερη ποιότητα και να διατηρήσουμε την ελάχιστη τιμή για την μικρή επιχείρησή σας. Ο ιδρυτής μας προέρχεται από μια οικογένεια που είχε μια μικρή επιχείρηση και καταλαβαίνει, περισσότερο από τους περισσότερους, πόσο σημαντικό είναι να έχεις έναν ιστότοπο που να δείχνει τι προσφέρεις στο κοινό."],
					["Δικαιώματα Κώδικα","Αφού λάβουμε την τελική πληρωμή, θα είστε ιδιοκτήτης του ιστότοπου και είστε ελεύθεροι να κάνετε ό,τι θέλετε με αυτόν. Εάν επιλέξετε να αφήσετε κάποιον να το αλλάξει με οποιονδήποτε τρόπο, δεν θα φέρουμε καμία ευθύνη και το πακέτο συντήρησης θα ακυρωθεί χωρίς επιστροφή χρημάτων. Κρατάμε αρχείο της δουλειάς μας. <br/><br/>Μπορεί στο παραπάνω σημείο να αποκτήσατε την κυριότητα του κώδικα, αλλά διατηρούμε επίσης την κυριότητα του κώδικα μας. Αυτό σημαίνει ότι μπορούμε να ανακυκλώσουμε κομμάτια του κώδικα μας σε άλλα project μας."],
					["Τιμές & Διαπραγματεύσεις","Μπορεί να διαπραγματευτούμε κάποιες τιμές, αλλά υπάρχουν κάποιες γραμμές που δεν μπορούμε να περάσουμε. Λάβετε υπόψη ότι η τιμολόγηση έχει διαμορφωθεί με συγκεκριμένα κριτήρια με βάση τον χρόνο και την προσπάθεια που χρειάζονται ορισμένα κομμάτια. Η ελάχιστη τιμή είναι η ελάχιστη τιμή. Δεν κάνουμε ποιοτικές περικοπές, και ως εκ τούτου το ελάχιστο μας ορίζεται εκεί που έχει οριστεί. Είμαστε πρόθυμοι να ακούσουμε τις ανάγκες και την προσέγγισή σας και θα διατηρήσουμε τις τιμές όσο το δυνατόν πιο κοντά στο ελάχιστο. Ως εκ τούτου, θα συζητήσουμε μαζί σας όσο χρειάζεται για να συμφωνήσουμε την τιμή."],
					["Πληρωμές και Επιστροφές","Θα υπάρξει προκαταβολή, η οποία είναι το μισό του ποσού που θα συμφωνήσουμε ότι θα σας κοστίσει ο ιστότοπος. Αυτό το ποσό δεν επιστρέφεται. Επομένως, εάν έχετε αλλάξει γνώμη, δεν θα μπορέσουμε να το επιστρέψουμε. \n\nΑφού σας παρουσιάσουμε το τελικό προϊόν και συμφωνήσετε ότι είναι καλοφτιαγμένο (πριν από τη φάση ανάρτησης, που είναι το τελευταίο στάδιο), θα σας ζητήσουμε το υπόλοιπο ποσό. Μόνο μετά την παραλαβή του, θα προχωρήσουμε στην ανάρτηση του ιστότοπού σας και θα σας παραδώσουμε τον κώδικα και όλα τα σχετικά με αυτόν."],
					["Κανόνες Συντήρησης","Το μηνιαίο πρόγραμμα συντήρησης καλύπτει δώδεκα (12) γύρους σέρβις, έναν (1) κάθε έναν (1) μήνα περίπου. Αυτοί οι γύροι υπηρεσιών περιλαμβάνουν ενημερώσεις για το περιεχόμενο (όπως η προσθήκη εικόνων ή προϊόντων που εμφανίζονται) και διορθώσεις σε στοιχεία που δεν συμπεριφέρονται σωστά. Η υπηρεσία αφορά μόνο τα υπάρχοντα κομμάτια. Έτσι, η προσθήκη νέων λειτουργιών δεν περιλαμβάνεται σε αυτό το πρόγραμμα. \nΑν οποιοσδήποτε άλλος εκτός από εμάς αλλάξει τον κώδικα θα έχει ως αποτέλεσμα την ακύρωση του μηνιαίου προγράμματος και δεν θα σας δοθεί επιστροφή χρημάτων. Εάν ο στόχος σας είναι να προσθέσετε επιπρόσθετες λειτουργίες (που δεν υπάρχουν ήδη), δεν καλύπτονται από αυτό το πακέτο."]
				]
			);
			setRightCard(
				[
					["Βασικό Πακέτο","Ένας ιστότοπος μικρής επιχείρησης απαιτεί ένα ελάχιστο επίπεδο πολυπλοκότητας. Δεν μπορεί να έχει μηχανισμό σύνδεσης ή εγγραφής, ούτε να έχει σύνδεση με βάση δεδομένων (όπως απαιτείται για παραγγελία προϊόντων). Επίσης, δεν μπορείτε να λαμβάνετε ειδοποιήσεις μέσω email από αυτόν τον ιστότοπο. Όλα αυτά απαιτούν άλλο κόστος. Λιγότερη πολυπλοκότητα σημαίνει ότι έχετε φθηνότερη τιμή. Σας υποσχόμαστε πως θα πάρετε περισσότερο από αυτό που πληρώσατε.","850€"],
					["Μηνιαία Συντήρηση"," Είναι προαιρετικό. Τι περιλαμβάνει; Περιλαμβάνει δύο (2) μεγάλους γύρους σέρβις συντήρησης, έναν (1) περίπου κάθε έξι (6) μήνες. Αυτοί οι γύροι συντήρησης περιλαμβάνουν αλλαγές στο περιεχόμενο ή διορθώσεις στα υπάρχοντα στοιχεία. Εάν θέλετε να προσθέσετε λειτουργίες που δεν υπάρχουν ήδη, ή απαιτούν περισσότερους γύρους συντήρησης, θα πρέπει να διαπραγματευτείτε μια τιμή. Εάν ο ιστότοπος έχει τροποποιηθεί από οποιονδήποτε άλλο εκτός από εμάς, αυτή η επιλογή δεν είναι πλέον διαθέσιμη.","100€ ανά εξάμηνο"],
					["Χρόνος Ανάπτυξης Ιστοσελίδας","Ο χρόνος που υπολογίζουμε ότι θα χρειαστεί για μια τυπική ιστοσελίδα είναι τέσσερις (4) μήνες. Ωστόσο, μπορεί να χρειαστεί να είναι έτοιμο σε συντομότερο χρονικό διάστημα. Μπορούμε να συζητήσουμε την συντόμευσης της διαδικασίας. Εάν συμφωνήσουμε ότι μπορεί να γίνει σε μικρότερο χρονικό διάστημα, θα πρέπει να πληρώσετε 250€ για κάθε μήνα λιγότερο από τους τέσσερις (4). Υπάρχει πιθανότητα κάποια πρότζεκτ να είναι έτοιμα πολύ νωρίτερα από τέσσερις (4) μήνες. Θα πληρώσετε επιπλέον μόνο εάν έχετε ζητήσει, εκ των προτέρων, να συντομεύσετε την αρχική περίοδο. Συνήθως κάνουμε λιγότερο από τέσσερις (4) μήνες."," λιγότερο από 250€ μηνιαίως"],
					["Ανάρτηση Σελίδας στο Internet","Αυτό θα καθοριστεί από το κόστος απόκτησης ενός domain για τον ιστότοπό σας και από τα έξοδα ανάρτησης της σελίδας του. Η τιμή θα καλύψει την ανάρτηση για τον πρώτο χρόνο. Στη συνέχεια το κόστος εγκατάστασης θα γίνεται με δική σας ευθύνη. Θα σας εξηγήσω προσωπικά πώς μπορείτε να πληρώνετε μόνοι σας τα έξοδα αυτά που επαναλαμβάνονται ετησίως. Θα σας δείξουμε την τιμή τόσο του domain όσο και των εξόδων ανάρτησης της σελίδας και θα μάθουμε μαζί τις λεπτομέρειες. Θα προχωρήσουμε στην ανάπτυξη του ιστότοπού σας μόνο αφού έχουμε συμφωνήσει σχετικά με τους όρους και τις λεπτομέρειες της ανάπτυξης.","Λιγότερο από 50€ (άπαξ)"],
					["Επιπρόσθετες Λειτουργίες","Εάν χρειάζεστε κάτι περισσότερο από τα βασικά, όπως μια σύνδεση βάσης δεδομένων ή άλλη πολύπλοκη λειτουργικότητα, μπορούμε να βρούμε μια τιμή. Όσο υψηλότερο είναι το επίπεδο πολυπλοκότητας, τόσο περισσότερη δουλειά απαιτείται. Επομένως, θα είναι πιο ακριβό. <br/> Θέλετε να προσθέσετε περιεχόμενο μόνοι σας; Θέλετε να κρατάτε αρχεία και πολλά άλλα χωρίς να χρειάζεστε συντήρηση και να παρακολουθείτε το κόστος εγκατάστασης; \n\n Μπορούμε να φτιάξουμε για εσάς ένα μηχάνημα server και να παρέχουμε τον προγραμματισμό του.","Διαπραγματεύσιμη"]
				]
			);
		}
	});

	const slideInto = (id) =>{
	    document.getElementById(id).style.top=0;
	};
	const slideOut = (id) =>{
	    document.getElementById(id).style.top="-100vh";
	};
	const contactFadeIn = () =>{
	    document.getElementById("contactPannel").style.visibility="visible";
	    document.getElementById("contactCard").style.visibility="visible";
	    document.getElementById("contactPannel").style.opacity="0.5";
	    document.getElementById("contactCard").style.opacity="1";
	    rightCardOutro();
	    leftCardOutro();
	    document.getElementById("emailResponse").innerHTML="";
	    document.getElementById("emailResponseMobile").innerHTML="";
	};
	const contactFadeOut = () =>{
	    document.getElementById("contactPannel").style.opacity="0";
	    document.getElementById("contactCard").style.opacity="0";
	    delay(500).then(() =>
		    document.getElementById("contactPannel").style.visibility="hidden",
		    document.getElementById("contactCard").style.visibility="hidden",
		    document.getElementById("name").value="",
		    document.getElementById("email").value="",
		    document.getElementById("message").value="",
		    document.getElementById("name").style.border="3px solid gray",
				document.getElementById("email").style.border="3px solid gray",
				document.getElementById("message").style.border="3px solid gray"
		    );
	};
	const leftCardEntrance = (index) =>{
		document.getElementById("leftCardOverlayTitle").innerHTML=leftCard[index][0];
		document.getElementById("leftCardOverlayBody").innerHTML=leftCard[index][1];
	    document.getElementById("leftCardOverlay").style.visibility="visible";
	    document.getElementById("leftCardOverlay").style.opacity="1";
	    rightCardOutro();
	};
	const leftCardOutro = () =>{
	    document.getElementById("leftCardOverlay").style.opacity="0";
	    delay(500).then(() =>
		    document.getElementById("leftCardOverlay").style.visibility="hidden"
		    );
	};
	const rightCardEntrance = (index) =>{
		document.getElementById("rightCardOverlayTitle").innerHTML=rightCard[index][0];
		document.getElementById("rightCardOverlayBody").innerHTML=rightCard[index][1];
		document.getElementById("priceValue").innerHTML=rightCard[index][2];
	    document.getElementById("rightCardOverlay").style.visibility="visible";
	    document.getElementById("rightCardOverlay").style.opacity="1";
	    leftCardOutro();
	};
	const rightCardOutro = () =>{
	    document.getElementById("rightCardOverlay").style.opacity="0";
	    delay(500).then(() =>
		    document.getElementById("rightCardOverlay").style.visibility="hidden"
		    );
	};
	
	const [paymentCode, setPaymentCode] = useState(false);
	const [currentCode, setCurrentCode] = useState(false);
	const [currentPayment, setCurrentPayment] = useState(["","","","","","",""]);
	const [realPayment, setRealPayment]= useState(false);

	return (
		<div id="service" className="service">
			<div className="serviceContainer">
				<div  className="smallScreenOnly">
					<img className="serviceImage" src="./assets/logoServ.png" />
					<h4>{content.serviceSubTitle1}</h4>
					<p>{content.serviceSubTitle2}</p>
					<p id="emailResponseMobile" className="confirmation"></p>
				</div>
				<div className="serviceCard">
					<div id="leftCardOverlay" className="cardOverlay">
						<div className="cardTitle">
							<h1 id="leftCardOverlayTitle"></h1>
							<button className="backBtn" onClick={() => {leftCardOutro()}}><p style={{width : "100%"}}>X</p></button>
						</div>
						<div id="leftCardOverlayBody" className="cardOverlayBody"></div>
					</div>
					<div className="cardTitle">
						<h1>{content.policies}</h1>
					</div>
					<div className="cardItem" onClick={() => {leftCardEntrance(0)}}><p>{leftCard[0][0]}</p></div>
					<div className="cardItem" onClick={() => {leftCardEntrance(1)}}><p>{leftCard[1][0]}</p></div>
					<div className="cardItem" onClick={() => {leftCardEntrance(2)}}><p>{leftCard[2][0]}</p></div>
					<div className="cardItem" onClick={() => {leftCardEntrance(3)}}><p>{leftCard[3][0]}</p></div>
					<div className="cardItem" onClick={() => {leftCardEntrance(4)}}><p>{leftCard[4][0]}</p></div>
				</div>
				<div  className="bigScreenOnly">
					<img className="serviceImage" src="./assets/logoServ.png" />
					<h3>{content.serviceSubTitle1}</h3>
					<p>{content.serviceSubTitle2}</p>
					<p id="emailResponse" className="confirmation"></p>
					<br/>
				</div>
				<div className="serviceCard">
					<div id="rightCardOverlay" className="cardOverlay">
						<div className="cardTitle">
							<h1 id="rightCardOverlayTitle"></h1>
							<button className="backBtn" onClick={() => {rightCardOutro()}}><p style={{width : "100%"}}>X</p></button>
						</div>
						<div>
							<p className="priceTag">{content.price}</p>
							<p className="priceValue" id="priceValue"></p>
						</div>
						<div id="rightCardOverlayBody" className="cardOverlayBody"></div>
					</div>
					<div className="cardTitle">
						<h1>{content.pricing}</h1>
					</div>
					<div className="cardItem" onClick={() => {rightCardEntrance(0)}}><p>{rightCard[0][0]}</p></div>
					<div className="cardItem" onClick={() => {rightCardEntrance(1)}}><p>{rightCard[1][0]}</p></div>
					<div className="cardItem" onClick={() => {rightCardEntrance(2)}}><p>{rightCard[2][0]}</p></div>
					<div className="cardItem" onClick={() => {rightCardEntrance(3)}}><p>{rightCard[3][0]}</p></div>
					<div className="cardItem" onClick={() => {rightCardEntrance(4)}}><p>{rightCard[4][0]}</p></div>
				</div>
			</div>
			<div className="infoFotter">
				<p>{content.serviceNote}</p>
			</div>
			
		</div>
	);
}

export default Service;