import React, {useState, useEffect} from 'react';
import NavItem from "./NavItem.js";
import Home from "./Home.js";
import Profile from "./Profile.js";
import Service from "./Service.js";
import Videos from "./Videos.js";
import Hosting from "./Hosting.js";
import Graphics from "./Graphics.js";
import Promo from "./Promo.js";
import './App.css';

import {AiOutlineArrowUp,AiOutlineSync} from "react-icons/ai";
import Translation from './texts.json';

function App() {
  const [navButtons, setNavButtons] = useState(["activeNavItem","navItem","navItem","navItem","navItem","navItem","navItem","navItem"]);
  const [currentTab, setCurrentTab] = useState("home");
  const [currentProfileSet, setProfileSet] = useState(0);
  const [containerOffset, setOffset] = useState(0);
  const [screenBlocking, setscreenBlocking] = useState(0); 
  const [language, setLanguage] = useState("English");
  const [navTitle, setNavTitle] = useState("Κεντρική Σελίδα");

  const [content,setContent]=useState(Translation.english);

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  const[currentLang,setcurrentLang]=useState("English");
  useEffect(()=>{
    if(language!=currentLang){
      if(language=="English"){
        setContent(Translation.english);

      }
      else if(language=="Ελληνικά"){
        setContent(Translation.greek);
      }
      setcurrentLang(language);
    }
  });

  const toTop = () =>{
    document.getElementById('container').scrollTop=0;
  };
  const updateOffset = event =>{
    console.log(containerOffset);
    setOffset(document.getElementById('container').scrollTop);
  }
  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  const updateBkgrColor = (current) =>{
    if (current=="home"){
      document.getElementById("app").style.backgroundColor="white";
      document.getElementById("app").style.boxShadow="inset 0 50vh 50vh -20vh #00888f";
      document.getElementById("tabId").innerHTML="Home";
    }
    else if (current=="aboutme"){
      document.getElementById("app").style.backgroundColor="#00888f";
      document.getElementById("app").style.boxShadow="inset 0 50vh 50vh -30vh white";
      document.getElementById("tabId").innerHTML="About Us";
    }
    else if (current=="demonstration"){
      document.getElementById("app").style.backgroundColor="#242B2E";
      document.getElementById("app").style.boxShadow="inset 0 50vh 50vh -20vh #00888f";
      document.getElementById("tabId").innerHTML="Demonstration";
    }
    else if (current=="service"){
      document.getElementById("app").style.backgroundColor="#0d161c";
      document.getElementById("app").style.boxShadow="inset 0 30vh 30vh -32vh #5cceff";
      document.getElementById("tabId").innerHTML="Policies & Payments";
    }
    else if (current=="videos"){
      document.getElementById("app").style.backgroundColor="#0d161c";
      document.getElementById("app").style.boxShadow="inset 0 30vh 30vh -32vh #b6d0d4";
      document.getElementById("tabId").innerHTML="Videos";
    }
    else if (current=="hosting"){
      document.getElementById("app").style.backgroundColor="#1f1f1f";
      document.getElementById("app").style.boxShadow="none";
      document.getElementById("tabId").innerHTML="Online Hosting";
    }
    else if (current=="graphics"){
      document.getElementById("app").style.backgroundColor="white";
      document.getElementById("app").style.boxShadow="inset 0 30vh 30vh -32vh black, inset 0 -30vh 30vh -32vh black";
      document.getElementById("tabId").innerHTML="Graphic Design";
    }
    else if (current=="message"){
      document.getElementById("app").style.backgroundColor="#00888f";
      document.getElementById("app").style.boxShadow="inset 0 -40vh 40vh -10vh #14232C, inset 0 40vh 40vh -10vh #14232C";
      document.getElementById("tabId").innerHTML="Contact Us";
    }
  }
  const changeCurrentTab = (newCurrentTab,currentNav) =>{
    if(currentTab!=newCurrentTab){
      toTop();
      //This changes the current tab:
      document.getElementById(currentTab).style.opacity="0";
      if(currentTab=="home"){
        document.getElementById("squareItemsOverlay").style.transition="right 0.25s";
        if(window.screen.width/window.screen.height > 1){
          document.getElementById("squareItemsOverlay").style.right="-35vw";
        }
        else{
          document.getElementById("squareItemsOverlay").style.right="-100vw";
        }
      }
      setscreenBlocking(1);
      delay(250).then(() =>
          setCurrentTab(newCurrentTab),
          updateBkgrColor(newCurrentTab)
          );
      delay(1250).then(() =>
          setscreenBlocking(0)
          );
      //This changes the active nav icon:
      var temp=["navItem","navItem","navItem","navItem","navItem","navItem","navItem","navItem"];
      temp[currentNav]="activeNavItem";
      setNavButtons(temp);
      closeMenu();
    }
  }
  const closeMenu = () =>{
    document.getElementById("menu").style.display="none";
    document.getElementById("menu").style.top="-100%";
  }
  const openMenu = () =>{
    document.getElementById("menu").style.display="inline-flex";
    delay(250).then(()=>
      document.getElementById("menu").style.top="0"
    );
  }
  const [permErrorMessage,setPermErrorMessage]=useState("");
  const findCountry= ()=>{
    document.getElementById("loadingApp").style.visibility="visible";
    const success =(position)=>{
      const latitude= position.coords.latitude;
      const longitude= position.coords.longitude;
      const geoApiUrl= 'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude='+latitude+'&longitude='+longitude+'&localityLanguage=en';
      fetch(geoApiUrl)
      .then(res => res.json())
      .then(data => {
        if(data.countryCode!="GR"){
          setLanguage("English");
          setContent(Translation.english);
        }
        else{
          setLanguage("Ελληνικά");
          setContent(Translation.greek);
        }
        document.getElementById("permissions").style.display="none";
        document.getElementById("loadingApp").style.visibility="hidden";
      })
    }
    const error = () =>{
      setPermErrorMessage("We were not able to aquire your Geolocation info.")
    }
    navigator.geolocation.getCurrentPosition(success,error);
  }
  return (
    <div id="app" className="App">
      <div id="tabId" className="tabId">Home</div>
      <div id="loadingApp" className="permInfoOverlay"><p><AiOutlineSync  className="spinObject"/></p></div>
      <div id="permissions" className="permissions">
        <div className="permissionCard"> 
          <div className="permissionCloseButton" onClick={()=>{document.getElementById("permissions").style.display="none";}}>X</div>
          <h2>Permissions</h2>
          <p>Do you agree to let us access your location information, so that we can adjust our website to your needs?</p>
          {permErrorMessage == "" && <div>
            <button className="permissionButton" onClick={()=>{findCountry()}}>Yes</button>
            <button className="permissionButton" onClick={()=>{document.getElementById("permissions").style.display="none";}}>No</button>
          </div>}
          <p>{permErrorMessage}</p>
          {permErrorMessage != "" && <div>
            <button className="permissionButton" onClick={()=>{document.getElementById("permissions").style.display="none";}}>Okay</button>
          </div>}
          <Promo/>
        </div>
        
      </div>
      { screenBlocking == 1 && <div class="defaultScreen"></div>}
      <div className="header-nav">
        <div className="tabContainer">
            <img src="./assets/logoCircle.png" className="cornerLogo"/>
            <button onClick={() => {changeCurrentTab("home",0)}}><NavItem icon="BiHome" class={navButtons[0]}/></button>
            <button onClick={() => {changeCurrentTab("hosting",5)}}><NavItem icon="BiCloud" class={navButtons[5]}/></button>
            <button onClick={() => {changeCurrentTab("graphics",6)}}><NavItem icon="BiPaint" class={navButtons[6]}/></button>
            <button onClick={() => {changeCurrentTab("videos",3)}}><NavItem icon="BiMoviePlay" class={navButtons[3]}/></button>
            <button onClick={() => {changeCurrentTab("service",4)}}><NavItem icon="BiShoppingBag" class={navButtons[4]}/></button>
            <button onClick={() => {changeCurrentTab("aboutme",1)}}><NavItem icon="AiOutlineInfo" class={navButtons[1]}/></button>
          <button onClick={() => {openMenu()}}><NavItem icon="BiMenu" class="navItem mobileOnlyNavItems"/></button>
          <div className="language">
            <p>Language:</p>
            <select id="langSelection" value={language} className="languageSelection" onChange={(e)=>{setLanguage(e.target.value)}}>
              <option>English</option>
              <option>Ελληνικά</option>
            </select>
          </div>

          {language =="Ελληνικά" && <img src="./assets/gr-circle.png" className="reactText" onClick={()=>{setLanguage("English")}}/>}
          {language =="English" && <img src="./assets/us-circle.png" className="reactText" onClick={()=>{setLanguage("Ελληνικά")}}/>}
        </div>
      </div>
      <div id="container" className="mainPartition" onScroll={updateOffset}>
        { currentTab == "home" && <Home onClick={changeCurrentTab} language={language}/> }
        { currentTab == "aboutme" && <Profile currentSet={0} language={language}/>}
        { currentTab == "service" && <Service onClick={changeCurrentTab} currentSet={0} language={language}/>}
        { currentTab == "videos" && <Videos currentSet={0} language={language}/>}
        { currentTab == "hosting" && <Hosting currentSet={0} language={language}/>}
        { currentTab == "graphics" && <Graphics currentSet={0} language={language}/>}
      </div>
      <div className="footer">
        <img  src="./assets/DNlogoLong.png" />
      </div>
      { containerOffset > 200 && <div className="backToTopBtn" onClick={() => {toTop()}}><AiOutlineArrowUp /></div> }
      <div id="menu" className="menuOverlayMobile">
        <div>
          <h1 className="menutitle">MENU</h1>
          { currentTab != "home" &&<div><button className="menuBtn" onClick={() => {changeCurrentTab("home",0)}}><NavItem icon="BiHome"/> {content.home}</button></div>}
          { currentTab != "hosting" && <div><button className="menuBtn" onClick={() => {changeCurrentTab("hosting",5)}}><NavItem icon="BiCloud"/> {content.onlineHosting}</button></div>}
          { currentTab != "graphics" && <div><button className="menuBtn" onClick={() => {changeCurrentTab("graphics",6)}}><NavItem icon="BiPaint"/> Graphic Design</button></div>}
          { currentTab != "videos" && <div><button className="menuBtn" onClick={() => {changeCurrentTab("videos",3)}}><NavItem icon="BiMoviePlay"/> {content.videos}</button></div>}
          { currentTab != "service" && <div><button className="menuBtn" onClick={() => {changeCurrentTab("service",4)}}><NavItem icon="BiShoppingBag"/> {content.PoliciesPayments}</button></div>}          { currentTab != "aboutme" && <div><button className="menuBtn" onClick={() => {changeCurrentTab("aboutme",1)}}><NavItem icon="AiOutlineInfo"/> {content.aboutUs}</button></div>}
        </div>
        <div className="closeBtnMenu"  onClick={() => {closeMenu()}}>x</div>
      </div>
    </div>
  );
}

export default App;
