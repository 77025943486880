import React, {useState} from 'react';
import NavItem from "./NavItem.js";
import axios from 'axios';
import {AiOutlineSync} from "react-icons/ai";

import './Videos.css';
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
function Videos(props) {
	const [currentVideo, setCurrentVideo] = useState("./assets/videos/default.mp4");
	const changeVideo = (videoName) =>{
		if(currentVideo != videoName){
			document.getElementById("player").pause();
			setCurrentVideo(videoName);
			document.getElementById("player").load();
			delay(500).then(() =>
				document.getElementById("player").play()
				);
		}
	}
	return (
		<div id="videos" className="videos">
			<div className="videoContainer">
				<div className="containerLeft">
					<video id="player" className="videoPlayer"  controls controlsList="nodownload" oncontextmenu="return false;">
					  <source src={currentVideo} type="video/mp4"/>
					  Your browser does not support the video tag.
					</video>
				</div>
				{props.language == "Ελληνικά" &&
				<div className="containerRigth">
					<div className="containterRightrow">
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Γιατροί.png" onClick={() => {changeVideo("./assets/videos/"+"Γιατροί"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Γιώργος_και_Άννα.png" onClick={() => {changeVideo("./assets/videos/"+"Γιώργος_και_Άννα"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Δικηγόρος.png" onClick={() => {changeVideo("./assets/videos/"+"Δικηγόρος"+".mp4")}}/>
						</div>
					</div>
					<div className="containterRightrow">
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Mobile_Responsive_Design.png" onClick={() => {changeVideo("./assets/videos/"+"Mobile_Responsive_Design"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/unique_websites.png" onClick={() => {changeVideo("./assets/videos/"+"unique_websites"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Online_hosting.png" onClick={() => {changeVideo("./assets/videos/"+"Online_hosting"+".mp4")}}/>
						</div>
					</div>
					<div className="containterRightrow">
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Hotels_Restaurants.png" onClick={() => {changeVideo("./assets/videos/"+"Hotels_Restaurants"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Careers.png" onClick={() => {changeVideo("./assets/videos/"+"Careers"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/κοινωνικό_μήνυμα.png" onClick={() => {changeVideo("./assets/videos/"+"κοινωνικό_μήνυμα"+".mp4")}}/>
						</div>
					</div>
				</div>
				}
				{props.language == "English" &&
				<div className="containerRigth">
					<div className="containterRightrow">
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Doctors.png" onClick={() => {changeVideo("./assets/videos/English/"+"Doctor"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/smallAndMediumBus.png" onClick={() => {changeVideo("./assets/videos/English/"+"smallAndMediumBus"+".mp4")}}/>
						</div>
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Politicians.png" onClick={() => {changeVideo("./assets/videos/English/"+"politicians"+".mp4")}}/>
						</div>
					</div>
					<div className="containterRightrow">
						<div className="containerRightItem">
							<img className="" src="./assets/videoImg/Vision.png" onClick={() => {changeVideo("./assets/videos/English/"+"Vision"+".mp4")}}/>
						</div>
					</div>
				</div>
				}
			</div>
		</div>
	);
}

export default Videos;