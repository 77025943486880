import React, {useEffect, useState} from 'react';
import NavItem from "./NavItem.js";
import axios from 'axios';
import {AiOutlineSync} from "react-icons/ai";

import './Hosting.css';

import Translation from './texts.json';

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
function Hosting(props) {
	const [content,setContent]=useState({});
	useEffect(()=>{
		if(props.language=="English"){
			setContent(Translation.english);
		}
		else if(props.language=="Ελληνικά"){
			setContent(Translation.greek);
		}
	});
	const [currentVideo, setCurrentVideo] = useState("./assets/videos/Mobile_Responsive_Design.mp4");
	const changeVideo = (videoName) =>{
		if(currentVideo != videoName){
			document.getElementById("player").pause();
			setCurrentVideo(videoName);
			document.getElementById("player").load();
			delay(500).then(() =>
				document.getElementById("player").play()
				);
		}
	}
	return (
		<div id="hosting" className="hosting">
			<div className="imageContainer"></div>
			<div className="textContainer">
				<h1>Online Hosting</h1>
				<h2>{content.onlineHostingSubTitle}</h2><br/>
				<p>
					{content.onlineHostingSubText1}
				</p>
				<p className="textContainerOwnBusinessText">
					{content.onlineHostingSubText2}
				</p>
			</div>
		</div>
	);
}

export default Hosting;