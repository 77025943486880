import React, {useEffect, useState} from 'react';
import NavItem from "./NavItem.js";
import axios from 'axios';
import {AiOutlineSync} from "react-icons/ai";

import './Graphics.css';

import Translation from './texts.json';

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
function Graphics(props) {
	const [content,setContent]=useState({});
	const [localCoords, setLocalCoords] = useState({x: 0, y: 0});
	const handleMouseMove = event => {
		var viewportOffset = event.target.getBoundingClientRect();
    setLocalCoords({
      x: event.clientX - Math.floor(viewportOffset.left),
      y: event.clientY - Math.floor(viewportOffset.top),
    });
    //console.log((event.target.offsetWidth/event.target.offsetHeight));
    var multiplierX= Math.floor(document.getElementById("zoomImg").offsetWidth/event.target.offsetWidth);
    var multiplierY= Math.floor(document.getElementById("zoomImg").offsetHeight/event.target.offsetHeight)/2;
    document.getElementById("zoomImg").src=event.target.src;
    zoomYes();
    document.getElementById("zoomContainer").style.width=((event.target.offsetWidth*1.5))+"px";
    document.getElementById("zoomContainer").style.height=(event.target.offsetHeight*1.5)+"px";
    document.getElementById("zoomImg").style.left=-(((localCoords.x * multiplierX)/2)+(localCoords.x * multiplierX * 0.25))+"px";
    document.getElementById("zoomImg").style.top=-(localCoords.y * multiplierY)+"px";
  };
	useEffect(()=>{
		if(props.language=="English"){
			setContent(Translation.english);
		}
		else if(props.language=="Ελληνικά"){
			setContent(Translation.greek);
		}
	});
	const [currentVideo, setCurrentVideo] = useState("./assets/videos/Mobile_Responsive_Design.mp4");
	const changeVideo = (videoName) =>{
		if(currentVideo != videoName){
			document.getElementById("player").pause();
			setCurrentVideo(videoName);
			document.getElementById("player").load();
			delay(500).then(() =>
				document.getElementById("player").play()
				);
		}
	}
	const zoomNot=()=>{
		document.getElementById("zoomContainer").style.display="none";
	}
	const zoomYes=()=>{
		document.getElementById("zoomContainer").style.display="block";
	}
	const [currentExampleImg,setCurrentExampleImg]=useState("bus_cards/3.png");
	const setExample=(key)=>{
		setCurrentExampleImg(("./assets/graphics/"+key));
		delay(150).then(() =>{
			document.getElementById("example").style.visibility="visible";
			document.getElementById("exampleImg").style.visibility="visible";
			document.getElementById("exampleImg").style.opacity="1";
			document.getElementById("example").style.opacity="1";
			}
		);
	}
	return (
		<div id="graphics" className="graphics">
			<div id="example" className="exampleImgContainer">
				<div className="centerIt">
					<div className="exampleImgFrame">
						<div className="closeBtn" onClick={()=>{document.getElementById("example").style.visibility="hidden";document.getElementById("exampleImg").style.visibility="hidden";setCurrentExampleImg("");document.getElementById("exampleImg").style.opacity="0";document.getElementById("example").style.opacity="0";}}>X</div>
						{currentExampleImg!="" && <img id="exampleImg" className="exampleImg" src={currentExampleImg}/>}
					</div>
				</div>
			</div>
			<div className="graphicaItem">
				<div className="graphicaItemImgContainer">
						<img onMouseMove={handleMouseMove} onMouseLeave={() =>zoomNot()} src="./assets/graphics/bus_cards/1.png" />
						<img onMouseMove={handleMouseMove} onMouseLeave={() =>zoomNot()} src="./assets/graphics/bus_cards/2.png" />
						<div className="photoExample" onClick={()=>{setExample("bus_cards/3.png")}}>Example</div>
				</div>
				<div className="graphicaItemTextContainer">
					<h1>{content.bCards}</h1>
					<p>{content.bCardsText}</p>
				</div>
			</div>
			<div className="graphicaItem">
				<div className="graphicaItemImgContainer">
						<img onMouseMove={handleMouseMove} onMouseLeave={() =>zoomNot()} src="./assets/graphics/brochure/1.png" />
						<img onMouseMove={handleMouseMove} onMouseLeave={() =>zoomNot()} src="./assets/graphics/brochure/2.png" />
						<div className="photoExample" onClick={()=>{setExample("brochure/3.png")}}>Example</div>
				</div>
				<div className="graphicaItemTextContainer">
					<h1>{content.brochure}</h1>
					<p>{content.brochureText}</p>
				</div>
			</div>
			<div className="graphicaItem">
				<div className="graphicaItemImgContainer">
						<img onMouseMove={handleMouseMove} onMouseLeave={() =>zoomNot()} src="./assets/graphics/leaflet/1.png" />
						<div className="photoExample"onClick={()=>{setExample("leaflet/2.png")}}>Example</div>
				</div>
				<div className="graphicaItemTextContainer">
					<h1>{content.leaflets}</h1>
					<p>{content.leafletsText}</p>
				</div>
			</div>
			<div className="graphicaItem">
				<div className="graphicaItemImgContainer">
						<img onMouseMove={handleMouseMove} onMouseLeave={() =>zoomNot()} src="./assets/graphics/sticker/sticker.png" />
						<div className="photoExample"onClick={()=>{setExample("sticker/2.png")}}>Example</div>
				</div>
				<div className="graphicaItemTextContainer">
					<h1>{content.stickers}</h1>
					<p>{content.stickersText}</p>
				</div>
			</div>
			<div className="graphicaItem">
				<div className="graphicaItemImgContainer">
						<img className="toCircle" src="./assets/graphics/sticker/SCAN_ME.png" />
						<div className="photoExample"onClick={()=>{setExample("sticker/1.png")}}>Example</div>
				</div>
				<div className="graphicaItemTextContainer">
					<h1>QR Codes</h1>
					<p>{content.qrCodeText}</p>
				</div>
			</div>
			<div className="onlyBigScreen">
				<div id="zoomContainer" className="zoomSquare">
					<img id="zoomImg" src="./assets/graphics/bus_cards/1.png"/>
				</div>
			</div>
		</div>

	);
}

export default Graphics;