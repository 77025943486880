import React, {useEffect, useState} from 'react';
import NavItem from "./NavItem.js";
import { BsFacebook, BsLinkedin, BsInstagram, BsYoutube } from "react-icons/bs";
import { SiGoogleads } from "react-icons/si";
import { BiShoppingBag, BiQr, BiPaint, BiData, BiCloud, BiDevices } from "react-icons/bi";
import './Home.css';

import { BiEnvelope } from "react-icons/bi";

import Translation from './texts.json';

import {Blurhash} from 'react-blurhash';

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
function Home(props) {
	const [content,setContent]=useState({});
	useEffect(()=>{
		if(props.language=="English"){
			setContent(Translation.english);
		}
		else if(props.language=="Ελληνικά"){
			setContent(Translation.greek);
		}
	});

	const toTop = () =>{
		document.getElementById('container').scrollTop=0;
	};
	const slideInto = (id) =>{
		toTop();
		document.getElementById(id).style.visibility="visible"
	  
	  if (window.screen.width>=window.screen.height) {document.getElementById(id).style.top=0;}
	  else{document.getElementById(id).style.top="6vh";}
	};
	const slideOut = (id) =>{
	    document.getElementById(id).style.top="-100vh";
	    delay(1000).then(() =>
	    	document.getElementById(id).style.visibility="hidden"
			);
	};
	const [currentSquare, setCurrentSquare] = useState("");
	const slideInSider = (id,squareSelected) =>{
		document.getElementById(id).style.visibility="visible"
		document.getElementById(id).style.right="0";
		document.getElementById(id).style.top="6vh";
		setCurrentSquare(squareSelected);
		toTop();
	}
	const slideOutSider = (id) =>{
		if(window.screen.width/window.screen.height > 1){
			document.getElementById(id).style.right="-35vw";
		}
		else{
			document.getElementById(id).style.top="-100vh";
		}
		delay(500).then(() =>
    	document.getElementById(id).style.visibility="hidden"
		);
	}
	const [videoOrLoggo, setVideoOrLoggo] = useState("logo");
	const changeLogo = (status) =>{
		delay(500).then(() => setVideoOrLoggo(status));
		if(status=="video"){
			delay(500).then(() =>document.getElementById("homeVideo").load());
			delay(700).then(() =>
				document.getElementById("homeVideo").play()
			);
		}
		if(status=="logo"){
			document.getElementById("homeVideo").pause();
			document.getElementById("homeVideo").currentTime = 0;
		}
	}
	const [imageList,setImageList]=useState({
			"./assets/statueOfLib.png":false,
			"./assets/nyc.png":false,
			"./assets/athensLocation.png":false,
			"./assets/athens.png":false,
			"./assets/myselfLogo1.png":false,
			"./assets/boxPhotos/boxPhoto1.png":false,
			"./assets/boxPhotos/boxPhoto2.png":false,
			"./assets/boxPhotos/boxPhoto3.png":false,
			"./assets/boxPhotos/boxPhoto4.png":false,
			"./assets/logoCircle.png":false
		});
	const[imageLoaded,setImageLoaded]=useState(false);
	useEffect(()=>{
		for (const [key, value] of Object.entries(imageList)) {
			if(!imageList[key]){
				const img = new Image()
				img.onload = () => {
					setImageList({
			      ...imageList,
			      [key]:true
			    });
				}
				img.src =key
			}
		}
	},[imageList,setImageList])

	const copyEmail= async() => {
		var copyText = "dndevelopmentstudio@outlook.com";
	  navigator.clipboard.writeText(copyText);
	  document.getElementById("emailPrompt").style.opacity=1;
	  delay(2000).then(()=>{document.getElementById("emailPrompt").style.opacity=0});
	};
	return (
		<div id="mobileContainer" className="mobileContainer">
			<p id="emailPrompt" className="emailPrompt bigScreenOnly"><BiEnvelope style={{position:"relative",top:"0.25vw"}}/> {content.emailPrompt}</p>
			<div id="NYpopUp" className="popUpTab">
				<div className="popUpTabBody">
					<div>
						<div className="closeBtn" onClick={() => {slideOut("NYpopUp");}}>X</div>
						<img className="popUpTabBodyCover" src="./assets/nycCover.png" />
						<img className="popUpTabBodyCoverLogo" src="./assets/statueOfLib.png"/>
						<div className="popUpTabTextContainer">
							<div className="popUpTabTextCards">
								<div>
									<h2>{content.locationNYCTitle}</h2>
									<br/>
									<p>
										{content.locationNYC}
									</p>
									</div>
							</div>
							<div><img className="popUpLogo" src="./assets/myselfLogo1.png" /></div>
							<div className="popUpTabTextCards">
								<div>
									<img className="popUpTabTextCardsImage" src="./assets/nycOffice.png"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="NJITpopUp" className="popUpTab">
				<div className="popUpTabBody">
					<div>
						<div className="closeBtn" onClick={() => {slideOut("NJITpopUp");}}>X</div>
						<img className="popUpTabBodyCover" src="./assets/athensCover.png" />
						<img className="popUpTabBodyCoverLogo" src="./assets/athensLocation.png"/>
						<div className="popUpTabTextContainer">
							<div className="popUpTabTextCards">
								<div>
									<img className="popUpTabTextCardsImage" src="./assets/athensOffice.png"/>
								</div>
							</div>
							<div><img className="popUpLogo" src="./assets/myselfLogo1.png" /></div>
							<div className="popUpTabTextCards">
								<div>
									<h2>{content.locationAthensTitle}</h2>
									<br/>
									<p>
										{content.locationAthens}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="squareItemsOverlay" className="squareItemsOverlay">
				<div className="closeBtn" onClick={() => {slideOutSider("squareItemsOverlay");}}>X</div>
				{currentSquare == "socialMedia" &&
				<div>
					<h1>Social Media</h1>
					<p className="squareItemsOverlayText">
						{content.socialMedia}
					</p>
					<div className="squareItemsOverlayFooter">
						<a href="https://www.facebook.com/profile.php?id=100092993353898" target="_blank"><BsFacebook/></a>
						<a href="https://www.linkedin.com/company/dn-development-studio/"><BsLinkedin/></a>
						<a href="https://www.instagram.com/dndevelopmentstudio/" target="_blank"><BsInstagram/></a>
					</div>
				</div>}
				{currentSquare == "graphics" &&
				<div>
					<h1>{content.graphicDesignTitle}</h1>
					<p className="squareItemsOverlayText">
						{content.graphicDesign}
					</p>
					<div className="squareItemsOverlayFooter">
						<img src="./assets/graphicsPen.png" />
					</div>
				</div>}
				{currentSquare == "carreer" &&
				<div>
					<h1>{content.careersTitle}</h1>
					<p className="squareItemsOverlayText">
						{content.careers}
					</p>
					<div className="squareItemsOverlayFooter">
						<img src="./assets/carreer.png" />
					</div>
				</div>}
				{currentSquare == "servers" &&
				<div>
					<h1>Servers Hosting</h1>
					<p className="squareItemsOverlayText">
						{content.servers}
					</p>
					<div className="squareItemsOverlayFooter">
						<img src="./assets/servers.png" />
					</div>
				</div>}
			</div>
			<div id="home" className="homeContainer">
				<div className="contactBtnHome bigScreenOnly" onClick={() => {copyEmail()}}><BiEnvelope style={{position:"relative",top:"0.2vw"}}/> {content.ContactingTitle}<br/><p style={{fontSize:"70%"}}>dndevelopmentstudio@outlook.com<br/>{content.phoneNum}</p></div>
				<div className="smallScreenOnly">
					<img style={{display: !imageList["./assets/myselfLogo1.png"] ? 'none' : 'block'}} src="./assets/myselfLogo1.png" className="homeLogo" />
					<div style={{display: imageList["./assets/myselfLogo1.png"] ? 'none' : 'block',overflow:"hidden"}} className="homeLogo">
						<Blurhash width="80vw" height="80vw" hash="LEA,:=j]00a|IUayt7of00j[~qWB" />
					</div>
					<div className="contactBtnHome smallScreenOnly" onClick={() => {copyEmail()}}><BiEnvelope style={{position:"relative",top:"1.25vw"}}/> {content.ContactingTitle}<p style={{fontSize:"55%"}}>dndevelopmentstudio@outlook.com<br/>{content.phoneNum}</p></div>
				</div>
				<div className="homeItem">
					<div className="leftHandItems backAndForth1" onClick={() => {slideInto("NYpopUp");}}>
						<div className="leftHandSubDivLeft" style={{display: !imageList["./assets/statueOfLib.png"] ? 'none' : 'inline-flex'}}><img src="./assets/statueOfLib.png"  /></div>
						<div className="leftHandSubDivRight" style={{display: !imageList["./assets/nyc.png"] ? 'none' : 'block'}}><img src="./assets/nyc.png" className="leftHandSubDivRightImg"/></div>
						<div style={{display: imageList["./assets/statueOfLib.png"] ? 'none' : 'block'}} className="leftHandSubDivLeft"></div>
						<div style={{display: imageList["./assets/nyc.png"] ? 'none' : 'block',width:" 70%",position:"relative",left:"2.5%"}} className="leftHandSubDivLeft"><Blurhash className="leftHandSubDivRightImg" width="100%" height="100%" hash="LjJc2xayoyof~paya}j[?Gt7jFWB" /></div>
					</div>
					<div className="leftHandItems backAndForth2" onClick={() => {slideInto("NJITpopUp");}}>
						<div className="leftHandSubDivLeft" style={{display: !imageList["./assets/athensLocation.png"] ? 'none' : 'inline-flex'}}><img src="./assets/athensLocation.png"  /></div>
						<div className="leftHandSubDivRight" style={{display: !imageList["./assets/athens.png"] ? 'none' : 'block'}}><img src="./assets/athens.png" className="leftHandSubDivRightImg"/></div>
						<div style={{display: imageList["./assets/athensLocation.png"] ? 'none' : 'block'}} className="leftHandSubDivLeft"></div>
						<div style={{display: imageList["./assets/athens.png"] ? 'none' : 'block',width:" 70%",position:"relative",left:"2.5%"}} className="leftHandSubDivLeft"><Blurhash className="leftHandSubDivRightImg" width="100%" height="100%" hash="LdIsB?%Lt7WC~pWBR+WB-oRjWCof" /></div>
					</div>
				</div>
				<div className="smallScreenOnly">
					{ props.language == "Ελληνικά" &&
					<video className="homeLogoOverlayVideo" playsinline  controls controlsList="nodownload" oncontextmenu="return false;">
					  <source src="./assets/videos/intro.mp4" type="video/mp4"/>
					  Your browser does not support the video tag.
					</video>}
					{ props.language == "English" &&
					<video className="homeLogoOverlayVideo" playsinline controls controlsList="nodownload" oncontextmenu="return false;">
					  <source src="./assets/videos/English/intro.mp4" type="video/mp4"/>
					  Your browser does not support the video tag.
					</video>}
				</div>
				<div className="bigScreenOnly">
					{ videoOrLoggo == "logo" && <div className="homeLogo">
						<img style={{display: !imageList["./assets/myselfLogo1.png"] ? 'none' : 'block'}} src="./assets/myselfLogo1.png" />
						<Blurhash style={{display: imageList["./assets/myselfLogo1.png"] ? 'none' : 'block'}} width="100%" height="100%" hash="LEA,:=j]00a|IUayt7of00j[~qWB" />
						<div className="homeLogoOverlay"  onClick={() => {changeLogo("video");}}><p>{content.clickVideo}</p></div>
					</div>}
					{ videoOrLoggo == "video" && <div className="homeLogo" onClick={() => {changeLogo("logo");}}>
						{ props.language == "Ελληνικά" &&
						<video id="homeVideo" className="homeLogoOverlayVideo"  controls controlsList="nodownload" oncontextmenu="return false;">
						  <source src="./assets/videos/intro.mp4" type="video/mp4"/>
						  Your browser does not support the video tag.
						</video>}
						{ props.language == "English" &&
						<video id="homeVideo" className="homeLogoOverlayVideo"  controls controlsList="nodownload" oncontextmenu="return false;">
						  <source src="./assets/videos/English/intro.mp4" type="video/mp4"/>
						  Your browser does not support the video tag.
						</video>}
					</div>}
				</div>
				<div className="homeItem">
					<div className="squareItem sqTOcir1" onClick={() => {slideInSider("squareItemsOverlay","socialMedia");}}>
							<img style={{visibility: !imageList["./assets/boxPhotos/boxPhoto1.png"] ? 'hidden' : 'visible'}} src="./assets/boxPhotos/boxPhoto1.png" className="socialLink"/>
							<div style={{display: imageList["./assets/boxPhotos/boxPhoto1.png"] ? 'none' : 'block',width:"100%",height:"100%",position:'absolute',top:0,left:0}}><Blurhash width="100%" height="100%" hash="LECj;J~V000L_N%2I;Os4TI:s,$*" /></div>
							<p className="squareItemP">Social Media</p>
						</div>
					<div className="squareItem sqTOcir2" onClick={() => {slideInSider("squareItemsOverlay","graphics");}}>
							<img style={{visibility: !imageList["./assets/boxPhotos/boxPhoto4.png"] ? 'hidden' : 'visible'}} src="./assets/boxPhotos/boxPhoto4.png" className="socialLink"/>
							<div style={{display: imageList["./assets/boxPhotos/boxPhoto4.png"] ? 'none' : 'block',width:"100%",height:"100%",position:'absolute',top:0,left:0}}><Blurhash width="100%" height="100%" hash="LEBsHSxZ8JMxCRRj%~%#8_tR,VIU" /></div>
							<p className="squareItemP">Graphics</p>
						</div>
					<div className="squareItem sqTOcir4" onClick={() => {slideInSider("squareItemsOverlay","carreer");}}>
						<img style={{visibility: !imageList["./assets/boxPhotos/boxPhoto3.png"] ? 'hidden' : 'visible'}} src="./assets/boxPhotos/boxPhoto3.png" className="socialLink"/>
						<div style={{display: imageList["./assets/boxPhotos/boxPhoto3.png"] ? 'none' : 'block',width:"100%",height:"100%",position:'absolute',top:0,left:0}}><Blurhash width="100%" height="100%" hash="LED1?ER601Di7%kq.8xu5sMx?Gxu" /></div>
						<p className="squareItemP">Careers</p>
					</div>
					<div className="squareItem sqTOcir3" onClick={() => {slideInSider("squareItemsOverlay","servers");}}>
						<img style={{visibility: !imageList["./assets/boxPhotos/boxPhoto2.png"] ? 'hidden' : 'visible'}} src="./assets/boxPhotos/boxPhoto2.png" className="socialLink"/>
						<div style={{display: imageList["./assets/boxPhotos/boxPhoto2.png"] ? 'none' : 'block',width:"100%",height:"100%",position:'absolute',top:0,left:0}}><Blurhash width="100%" height="100%" hash="LA8q?Jt7Q,og_4ofITaeaIayIoWA" /></div>
						<p className="squareItemP">Servers</p>
					</div>
				</div>
				<div id="iconBar" className="iconBar">
					<p><BiQr/> Digital Marketing</p>
					<p><BiShoppingBag/> E-Commerce</p>
					<p><BiData/> Database Management</p>
					<p><BiCloud/> Online Hosting</p>
					<p><BiPaint/> Graphic Design</p>
					<p><BiDevices/> Mobile Responsiveness</p>
				</div>
				<button className="servicetBtn" onClick={() => {props.onClick("service",4);toTop();}}>{content.serviceButton}</button>
			</div>
		</div>
	);
}

export default Home;